import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate"

export default createStore({
  state: {
    user: null,
    currency: "BTC",
    readableNotifications: false,
    isBet: false,
    language: "ru",
    siteInfo: {
      logoUrl: "",
      darkLogoUrl: "",
      siteName: "CryptoPulse"
    }
  },
  getters: {
    getUserData(state) {
      return state.user
    },
    getCurrency(state) {
      return state.currency
    },
    getReadable(state) {
      return state.readableNotifications
    },
    getIsBet(state) {
      return state.isBet
    },
    getLanguage(state) {
      return state.language
    },
    getMirror(state) {
      return state.siteInfo
    }
  },
  mutations: {
    setUserData(state, data) {
      state.user = data
    },
    setCurrency(state, data) {
      state.currency = data
    },
    setReadable(state, data) {
      state.readableNotifications = data
    },
    setIsBet(state, data) {
      state.isBet = data
    },
    setLanguage(state, data) {
      state.language = data
    },
    setMirror(state, data) {
      state.siteInfo.logoUrl = data.logoUrl
      state.siteInfo.darkLogoUrl = data.darkLogoUrl
      state.siteInfo.siteName = data.siteName
    },
    logout(state) {
      state.user = null
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState()]
})
