<template>
	<div class="bodyDashboard">
		<div class="components" ref="components">
			<SideBar @newActive="changeSideActive" ref="hideSide" />
			<ProfileComponent v-if="active == 'profile'" />
			<WalletComponent v-if="active == 'wallet'" />
			<ActivesComponent v-if="active == 'actives'" @onChangeCurrency="changeCurrency" />
			<FAQComponent v-if="active == 'faq'" />
			<NotificationsComponent v-if="active == 'notifications'" />
		</div>

		<div class="workSpace">
			<DashBoard ref="currency" />
		</div>
	</div>
	<ErrorFullPage v-show="isError" />
</template>
<script>
import ActivesComponent from '@/components/ActivesComponent.vue'
import ErrorFullPage from '@/components/ErrorFullPage.vue'
import FAQComponent from '@/components/FAQComponent.vue'
import NotificationsComponent from '@/components/NotificationsComponent.vue'
import ProfileComponent from '@/components/ProfileComponent.vue'
import SideBar from '@/components/SideBar.vue'
import WalletComponent from '@/components/WalletComponent.vue'
import { useSocketIO } from '@/socket'
import axios from 'axios'
import { ref } from 'vue'
import { useToast } from 'vue-toastification'
import DashBoard from './DashBoard.vue'
// @ is an alias to /src

export default {
	name: 'DashboardPage',
	data() {
		return {
			active: 'home',
			currency: ref(null),
			showPassword: false,
			isError: false,
		}
	},
	setup() {
		const toast = useToast()
		return { toast }
	},
	components: {
		SideBar,
		ProfileComponent,
		DashBoard,
		WalletComponent,
		ActivesComponent,
		FAQComponent,
		NotificationsComponent,
		ErrorFullPage,
	},
	methods: {
		changeSideActive(data) {
			this.active = data.sideActive
			this.active = data.sideActive
		},
		changeCurrency(data) {
			this.$refs.currency.changeCurrency(data)
		},
		loadUserData() {
			axios
				.get(`https://${process.env.VUE_APP_SERVERHOST}/api/users/me`, {
					headers: {
						Authorization: 'Bearer ' + this.$store.state.user.token,
					},
				})
				.then(res => {
					this.$store.commit('setUserData', res.data.result)
				})
				.catch(() => {
					this.isError = true
				})
		},
		handleClickSidebar(event) {
			const componentsElement = this.$refs.components
			if (componentsElement && !componentsElement.contains(event.target)) {
				this.active = 'home'
				let elems = document.querySelectorAll('.sideButton')
				elems.forEach(elem => {
					elem.className = 'sideButton'
					elem.children[0].className = ''
				})
				this.sideActive = event.target.id
				let div = document.querySelector('#home')
				div.classList.add('activeSideButton')
				let img = div.querySelector('img')
				img.classList.add('activeSideButtonSvg')
			}
		},
		connectToSocket() {
			let { socket } = useSocketIO(this.$store.getters.getUserData.token)
			socket.on('NOTIFICATION', msg => {
				console.log(msg)
				if (msg.data.type == 'VERIFICATION' || msg.data.type == 'TEXT') this.toast.info(msg.data.message)
				else if (msg.data.type == 'PAYMENT') this.toast.success(msg.data.message)
				this.$store.commit('setReadable', true)
				this.loadUserData()
			})
			socket.on('BET', msg => {
				console.log(msg)
				this.$store.commit('setIsBet', false)
				if (msg.data.outcome == 'WIN') this.toast.success(this.$store.getters.getLanguage == "ru" ? `Ваш доход составил ${msg.data.amount*2}₽` : `Your income was ${msg.data.amount*2}₽`)
				else this.toast.error(this.$store.getters.getLanguage == "ru" ? `Ваш проигрыш ${msg.data.amount}₽` : `Your loss is ${msg.data.amount}₽`)
			})
			// socket.on('connect_error', () => {
			// 	this.isError = true
			// })
		},
	},
	mounted() {
		// this.checkConnection()
		this.connectToSocket()
		this.loadUserData()
		document.addEventListener('click', this.handleClickSidebar)
	},
	beforeUnmount() {
		document.removeEventListener('click', this.handleClickSidebar)
	},
}
</script>
<style scoped>
.bodyDashboard {
	position: absolute;
	background: url('../assets/background.png');
	background-size: 100%;
	background-repeat: no-repeat;
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}
.workSpace {
	width: 100%;
	height: 100vh;
}
.components {
	display: flex;
	position: absolute;
	left: 0;
	z-index: 9999;
}
@media (max-width: 968px) {
	.components {
		position: static;
	}
	.bodyDashboard {
		background-image: url('../assets/backgroundMobile.png');
		background-position: 0 50vh;
		background-size: 200%;
	}
}
</style>
