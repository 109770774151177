<template>
  <div class="actives">
    <p class='activesHead'>{{ $t("actives") }}</p>
    <div class='activesList' v-loading='loading' :element-loading-svg="svg" element-loading-svg-view-box="-10, -10, 50, 50" element-loading-background="rgba(0, 0, 0, 0.8)">
      <div class='activeBlock' id='BTC' @click='changeActive'>
        <div>
          <img src="../assets/logo/btc.svg"/>
          <p>BTC/USD</p>
        </div>
        <p style='color:#727272;'>≈ ${{ rates.BTC }}</p>
      </div>
      <div class='activeBlock' id='ETH' @click='changeActive'>
        <div>
          <img src="../assets/logo/eth.svg"/>
          <p>ETH/USD</p>
        </div>
        <p style='color:#727272;'>≈ ${{ rates.ETH }}</p>
      </div>
      <div class='activeBlock' id='LTC' @click='changeActive'>
        <div>
          <img src="../assets/logo/ltc.svg"/>
          <p>LTC/USD</p>
        </div>
        <p style='color:#727272;'>≈ ${{ rates.LTC }}</p>
      </div>
      <div class='activeBlock' id='ADA' @click='changeActive'>
        <div>
          <img src="../assets/logo/ada.svg"/>
          <p>ADA/USD</p>
        </div>
        <p style='color:#727272;'>≈ ${{ rates.ADA }}</p>
      </div>
      <div class='activeBlock' id='BNB' @click='changeActive'>
        <div>
          <img src="../assets/logo/bnb.svg"/>
          <p>BNB/USD</p>
        </div>
        <p style='color:#727272;'>≈ ${{ rates.BNB }}</p>
      </div>
      <div class='activeBlock' id='DOGE' @click='changeActive'>
        <div>
          <img src="../assets/logo/doge.png" style='width:30px;'/>
          <p>DOGE/USD</p>
        </div>
        <p style='color:#727272;'>≈ ${{ rates.DOGE }}</p>
      </div>
      <div class='activeBlock' id='SOL' @click='changeActive'>
        <div>
          <img src="../assets/logo/sol.svg"/>
          <p>SOL/USD</p>
        </div>
        <p style='color:#727272;'>≈ ${{ rates.SOL }}</p>
      </div>
      <div class='activeBlock' id='XLM' @click='changeActive'>
        <div>
          <img src="../assets/logo/xlm.svg"/>
          <p>XLM/USD</p>
        </div>
        <p style='color:#727272;'>≈ ${{ rates.XLM }}</p>
      </div>
      <div class='activeBlock' id='RAY' @click='changeActive'>
        <div>
          <img src="../assets/logo/ray.png" style='width:30px;'/>
          <p>RAY/USD</p>
        </div>
        <p style='color:#727272;'>≈ ${{ rates.RAY }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { ref } from 'vue'
export default {
  name: 'ActivesComponent',
  data() {
    return {
      loading: ref(true),
      active: this.$store.getters.getCurrency,
      svg: `
        <path class="path" d="
          M 30 15
          L 28 17
          M 25.61 25.61
          A 15 15, 0, 0, 1, 15 30
          A 15 15, 0, 1, 1, 27.99 7.5
          L 15 15
        " style="stroke-width: 4px; fill: rgba(0, 0, 0, 0)"/>
      `, 
      rates: {
        ADA: 0.349596,
        BNB: 562.77, 
        BTC: 61937, 
        DOGE: 0.108903, 
        ETH: 2415.09, 
        LTC: 66.45, 
        RAY: 1.73, 
        SOL: 143.04, 
        XLM: 0.092098
      }
    }
  },
  methods: {
    changeActive(event) {
      let e = event.target.closest('.activeBlock')
      let elems = document.querySelectorAll('.activeBlock')
      elems.forEach(elem => {
        elem.className = "activeBlock"
        elem.children[0].className = ""
      });
      document.querySelectorAll('.circleActive').forEach(e => e.remove());
      let circle = document.createElement('img')
      circle.src = require("@/assets/logo/useActive.svg")
      circle.className = "circleActive"
      circle.style = "position: absolute;right:15px;"
      e.classList.add('useActive')
      e.append(circle)
      this.$store.commit('setCurrency', e.id)
      this.$emit('onChangeCurrency', e.id)
      window.location.reload()
    },
    getRates() {
      axios.get(`https://${process.env.VUE_APP_SERVERHOST}/api/rates`)
      .then(res => {
        this.rates = res.data.result
        this.loading = ref(false)
      })
    },
    setActive() {
      document.querySelectorAll('.circleActive').forEach(e => e.remove());
      let circle = document.createElement('img')
      circle.src = require("@/assets/logo/useActive.svg")
      circle.className = "circleActive"
      circle.style = "position: absolute;right:15px;"
      let elem = document.getElementById(this.active)
      elem.classList.add('useActive')
      elem.append(circle)
    }
  },
  mounted() {
    this.user = this.$store.getters.getUserData
    this.getRates()
    this.setActive()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.actives {
  height:100vh;
  width: 20vw;
  background:black;
  /* position: absolute; */
  border-left: 1px solid rgba(77, 77, 77, 0.452);
  border-right: 1px solid rgba(77, 77, 77, 0.452);
  /* left:6%; */
  text-align: left;
  padding: 20px;
  scrollbar-color: #ffffff #000000;
  scrollbar-width: thin;
  overflow: auto;
}
.activesList {
  margin-top:5%;

}
.activesHead {
  font-weight: 700;font-size: 18px;margin-top: 10%;
}
.activeBlock {
  display: flex;
  align-items: center;
  background-color: #141414;
  border-radius: 8px;
  padding: 16px 14px 16px 14px;
  margin-bottom: 5%;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid #FFFFFF0D;
  position: relative;
}
.activeBlock div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.useActive {
  /* border-image: linear-gradient(180deg, #007558 0%, #00FFBF 100%) 1; */
  background: linear-gradient(#141414, #141414 0) padding-box,
  linear-gradient(180deg, #007558 0%, #00FFBF 100%) border-box;
}
.activeBlock p {
  margin-left:15px;
}
@media (max-width:1400px) {
  .activeBlock {
    flex-direction: column;
  }
}
@media (max-width:968px) {
  .actives {
    width:100%;
    background-image: url('../assets/backgroundMobile.png');
    background-position: 0 50vh;
    background-size: 200%;
    background-repeat: no-repeat;
    position: absolute;
    top:0;
    z-index: 8;
  }
  .activesBlock {
    margin-bottom: 3%;
    padding:17px
  }
  .activesHead {
    font-size: 24px;
  }
  .activesList {
    overflow-y: auto;
    height: 80%;
  }
  .activeBlock {
    flex-direction: row;
  }
}
</style>
