import Highcharts from "highcharts";
import StockModule from "highcharts/modules/stock";
import HighchartsVue from "highcharts-vue";
import { createApp } from 'vue'
import Toast from "vue-toastification"
// Import the CSS or use your own!
import "vue-toastification/dist/index.css"
import App from './App.vue'
import router from './router'
import store from './store'
import {createI18n} from "vue-i18n";
import en from "../src/assets/locales/en.json";
import ru from "../src/assets/locales/ru.json";

const messages = {
    en,
    ru
}

const i18n = createI18n({
    locale: 'ru',
    messages
})
StockModule(Highcharts);
createApp(App).use(store).use(router).use(HighchartsVue).use(i18n).use(Toast, {pauseOnFocusLoss: false}).mount('#app')
