<template>
  <div class="faq">
    <p class='faqHead'>FAQ</p>
    <div class='questions'>
      <details>
          <summary>{{ $t('question1', {siteName: this.$store.state.siteInfo.siteName}) }}</summary>
          <p>{{ $t('answer1') }}</p>
        </details>
        <details>
          <summary>{{ $t('question2') }}</summary>
          <p>{{ $t('answer2', {siteName: this.$store.state.siteInfo.siteName}) }}</p>
        </details>
        <details>
          <summary>{{ $t('question3') }}</summary>
          <p>{{ $t('answer3') }}</p>
        </details>
        <details>
          <summary>{{ $t('question4') }}</summary>
          <p>{{ $t('answer4') }}</p>
        </details>
        <details>
          <summary>{{ $t('question5') }}</summary>
          <p>{{ $t('answer5') }}</p>
        </details>
        <details>
          <summary>{{ $t('question6') }}</summary>
          <p>{{ $t('answer6') }}</p>
        </details>
        <details>
          <summary>{{ $t('question7') }}</summary>
          <p>{{ $t('answer7') }}</p>
        </details>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FAQComponent',
  data() {
    return {
      active: "profile",
      user: {}
    }
  },
  methods: {
    
  },
  mounted() {
    this.user = this.$store.getters.getUserData
  }
}
</script>

<style scoped>
.faq {
  height:100vh;
  width: 20vw;
  background:black;
  border-left: 1px solid rgba(77, 77, 77, 0.452);
  border-right: 1px solid rgba(77, 77, 77, 0.452);
  text-align: left;
  padding: 20px;
  scrollbar-color: #ffffff #000000;
  scrollbar-width: none;
  overflow: auto;
}
.faqHead {
  font-weight: 700;font-size: 18px;margin-top: 10%;margin-bottom: 5%;
}
.questions details {
  background-color: #141414;
  border: 1px solid #FFFFFF0D;
  padding: 16px 14px 16px 14px;
  border-radius: 8px;
  margin-bottom: 15px;
}
.mostQuestionsBlock {
  width: 60%;
  margin: 0 auto;
  text-align: left;
  font-size:22px;
  color: #FFFFFF;
}

.questions summary {
  cursor:pointer;
  user-select: none;
  display: grid;
  align-items: center;
  grid-template-columns: 99fr 1fr;
}
.questions details p {
  margin-top:10px;
  color: #FFFFFF80;
  font-size: 14px;
}
summary::after {
    content: "+";
    color: #fff;
    font-size: 1.5em;
    font-family: 'Proxima Nova Light';
    font-weight: 100;
    padding: 0;
    text-align: center;
    width: 20px;
    justify-self: right;
    transition: 0.2s;

}
details[open] > summary::after {
  transform: rotate(45deg);
  transition: 0.2s;
}
summary::marker {
    content: "";
}
@media (max-width:968px) {
  .faq {
    width:100%;
    background-image: url('../assets/backgroundMobile.png');
    background-position: 0 50vh;
    background-size: 200%;
    background-repeat: no-repeat;
    position: absolute;
    top:0;
    z-index: 8;
  }
  .questions {
    margin-bottom: 3%;
    overflow-y: auto;
    height:80%;
  }
  .faqHead {
    font-size: 24px;
  }
  .questions details summary {
    font-size: 16px;
  }
}
</style>
