<template>
  <div class='bodyAuth'>
    <div class="auth">
      <div class='pulseLogo'>
        <a href='/'>
          <div style="display: flex; justify-content: center; align-items: center;">
            <img :src="this.$store.state.siteInfo.logoUrl" width="40" v-if="this.$store.state.siteInfo.logoUrl"/>
            <img src="../assets/logo-light.svg" width="40" v-else/>
            <p class='logoName'>{{ this.$store.state.siteInfo.siteName }}</p>
          </div>
        </a>
      </div>
      <p style='font-size: 24px; font-family: "Proxima Nova Bold"; margin:15px;'>{{ $t("signIn") }}</p>
      <div class='authInput'>
        <input v-model='login' oninput="this.value = this.value.replace(/\s/g, '')"  :placeholder="$t('placeholderLogin')"/>
      </div>
      <div class='authInput'>
        <input v-model='password' :placeholder="$t('placeholderPassword')" type='password' id='password'/>
        <img v-if='!showPassword' src='../assets/eye-show.svg' style='width:24px;' @click='showPasswordButton'/>
        <img v-else src='../assets/eye-hide.svg' style='width:24px;' @click='showPasswordButton'/>
      </div>
      <div class='authButton' @click='authorization'>
        {{ $t("signIn") }}
      </div>
      <div class='regButton'>
        <p style='color: #0000005C;'>{{ $t("notHaveAccount") }} <a href='/register' style='color: black; font-weight: 700;'>{{ $t("signUp") }}</a></p>
      </div>
    </div>
  </div>
  
</template>
<script>
// @ is an alias to /src
import axios from 'axios'
import { useToast } from 'vue-toastification'
export default {
  name: 'AuthPage',
  data() {
    return {
      login: "",
      password: "",
      showPassword: false
    }
  },
  setup() {
    const toast = useToast()
    return { toast }
  },
  methods: {
    showPasswordButton() {
      this.showPassword = !this.showPassword
      let pass = document.getElementById('password')
      if (this.showPassword) pass.type = 'text'
      else pass.type='password'
    },
    authorization() {
      axios.post(`https://${process.env.VUE_APP_SERVERHOST}/api/auth/login`, {
        login: this.login.trim(),
        password: this.password
      })
      .then(res => {
        if (res.status == 201) {
          this.$store.commit('setUserData', res.data.result)
          this.toast.success(`${this.$store.getters.getLanguage == "ru" ? "Вы успешно вошли" : "You have successfully logged in"}`)
          console.log(res.data)
          window.location.href = "/dashboard"
        }
      })
      .catch(err => {
        console.log(err)
        this.toast.error(err.response.data.message, {timeout: 2000})
      })
    }
  }
}
</script>
<style scoped>
.bodyAuth {
  background-image: url('../assets/firstBlock.png');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 0 30vh;
  width:100vw;
  height:100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.auth {
  background-color: white;
  color: black;
  border-radius: 15px;
  width:25%;
  padding:30px;
  display: flex;
  flex-direction: column;
}
.authInput {
  border: 1px solid #0000005C;
  display: flex;
  border-radius:12px;
  padding: 5px;
  margin-bottom: 3%;
  height: 50px;
}
.authInput img {
  cursor: pointer;
}
.authInput input {
  border: 1px solid transparent;
  outline: none;
  padding-left: 15px;
  font-size: 18px;
  width: 90%;
  height: 100%;
}
.authInput input::placeholder {
  color: #0000005E;
}
.authButton {
  background-color: #000000;
  border-radius: 7px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  height: 50px;
  cursor: pointer;
}
.regButton {
  margin-top:15px;
}
.logoName {
  font-family: 'Proxima Nova Bold';
  font-weight: 700;
  font-size: 22px;
  margin-left:10px;
}
@media(max-width:968px) {
  .auth {
    width: 90%;
  }
  .bodyAuth {
    background-position: 0vw 50vh;
    background-size:150%;
  }
}
</style>
