<template>
  <router-view/>
</template>

<script>
import axios from 'axios';
import { useI18n } from 'vue-i18n';
export default {
  setup() {
    const { locale } = useI18n()
    return { locale }
  },
  methods: {
    setFavicon(url) {
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        link.type = "image/png"; // Указываем PNG
        document.head.appendChild(link);
      }
      link.href = url;
    }
  },
  mounted() {
    this.locale = this.$store.getters.getLanguage
    axios.get(`https://${process.env.VUE_APP_SERVERHOST}/api/mirror`)
    .then(res => {
      console.log(res)
      if (res.data.result.siteName) {
        this.$store.commit('setMirror', res.data.result)
        document.title = res.data.result.siteName
        this.setFavicon(res.data.result.logoUrl)
      }
      else {
        let mirror = {
          siteName: "CryptoPulse",
          logoUrl: null,
          darkLogoUrl: null
        }
        this.$store.commit('setMirror', mirror)
        document.title = "CryptoPulse"
        this.setFavicon("https://xapiimagehandler.com/files/uRzM7t7eWavO.png")
      }
    })
    .catch(err => {
      console.log(err)
      let mirror = {
        siteName: "CryptoPulse",
        logoUrl: null,
        darkLogoUrl: null
      }
      this.$store.commit('setMirror', mirror)
      document.title = "CryptoPulse"
      this.setFavicon("https://xapiimagehandler.com/files/uRzM7t7eWavO.png")
    })
    const bbb = document.createElement("script");
    bbb.innerHTML  = `(function(d,t) {
      var BASE_URL="https://xsupportspanel.co";
      var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
      g.src=BASE_URL+"/packs/js/sdk.js";
      g.defer = true;
      g.async = true;
      s.parentNode.insertBefore(g,s);
      g.onload=function(){
        window.chatwootSDK.run({
          websiteToken: 'mK6DZVuRJi7VUr5hHAgS5g7s',
          baseUrl: BASE_URL
        })
      }
    })(document,"script");`
    document.body.appendChild(bbb);
  }
}
</script>
<style>

#app {
  font-family: 'Proxima Nova Regular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
  font-weight: 400;
}
html {
  background-color: #000000;
  scroll-behavior: smooth;
}
p {
  margin: 0;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
@font-face {
  font-family: 'Proxima Nova Regular';
  src: url("assets/fonts/proximanova_regular.ttf");
}
@font-face {
  font-family: 'Proxima Nova Bold';
  src: url("assets/fonts/proximanova_bold.otf");
}
* {
  box-sizing: border-box;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
}
img {
  -webkit-user-drag: none;
  user-select: none;
}
a {
  text-decoration: none;
  color:#000000;
}
html {
  scrollbar-color: #ffffff #000000;
  scrollbar-width: thin;
}
.woot-widget-bubble {
  bottom:10% !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
</style>

<!-- <script>
  (function(d,t) {
    var BASE_URL="https://xsupportspanel.co";
    var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
    g.src=BASE_URL+"/packs/js/sdk.js";
    g.defer = true;
    g.async = true;
    s.parentNode.insertBefore(g,s);
    g.onload=function(){
      window.chatwootSDK.run({
        websiteToken: 'mK6DZVuRJi7VUr5hHAgS5g7s',
        baseUrl: BASE_URL
      })
    }
  })(document,"script");
</script> -->
